<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-02 16:47:58
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-03 10:53:13
 * @Description: description
-->
<template>
  <div class="staffedit-box">
    <div class='bg-whilt editInfo'>
      <label class='label' v-if="formData.id">
        <span class='labelTitle'>店员编号</span>
        <input type='span' maxlength='50' disabled="true" placeholder="店员编号" v-model='formData.id' />
      </label>
      <label class='label' @click="showStoreBox">
        <span class='labelTitle'>所属门店 <span class="mcolor">*</span></span>
        <input type="text" disabled="true" placeholder="请选择所属门店" v-model="formData.shopName" />
      </label>
      <van-popup v-model:show="showStore" round position="bottom">
        <van-picker
          :columns="StoreList"
          :columns-field-names="customFieldName"
          @cancel="showStore = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <label class='label'>
        <span class='labelTitle'>店员名称 <span class="mcolor">*</span></span>
        <input type='span' maxlength='50' placeholder="店员名称" v-model='formData.clerkName' bindinput='bindInput' data-name="clerkName" />
      </label>
      <label class='label'>
        <span class='labelTitle'>联系方式</span>
        <input type='span' maxlength='50' placeholder="店员联系方式" v-model='formData.clerkPhone' bindinput='bindInput' data-name="clerkPhone" />
      </label>
      <label class='label'>
        <span class='labelTitle'>备注</span>
        <input type='span' maxlength='200' placeholder="备注" v-model='formData.remark' bindinput='bindInput' data-name="remark" />
      </label>
    </div>
    <div class="opt-box">
      <button class='btn apply-btn center float-ani' :disabled="buttonClicked" @click="submitForm">保存</button>
    </div>  
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import {  getStoreListApi } from '@/api/store'
import { getStaffDetail, saveStaffPage } from '@/api/staff'
export default {
  name:'staffedit',
  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      formData: {},
      id: null,
      buttonClicked: false,
      customFieldName: {
        text: 'shopName'
      },
      StoreList: [],
      showStore: false
    })

    onMounted(async() => {
      var toast1 = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      const { rows } = await getStoreListApi()
      state.StoreList = rows;
      const { id } = route.params
      if (!id) return;
      const { data } = await getStaffDetail(id)
      state.id = id;
      state.formData = data;
      toast1.clear();
    })

    const showStoreBox = () => {
      state.showStore = true
    }

    const onConfirm = (e) => {
      state.showStore = false
      state.formData.shopName = e.shopName;
      state.formData.shopId = e.id;
    }

    // 保存
    const submitForm = () => {
      if(!state.formData.shopName){
        Toast('请选择所属门店！');
        return false;
      }
      if(!state.formData.clerkName){
        Toast('请填写店员名称！');
        return false;
      }
      state.buttonClicked = true
      let url = state.id ? '/wxapp/merClerk/edit' : '/wxapp/merClerk/add'
      saveStaffPage(url, state.formData).then(res => {
        Toast(res.msg);
        state.buttonClicked = false
        if (res.code == 200) {
          setTimeout(() => {
            router.go(-1)
          },1500)
        }
      })
    }

    return {
      ...toRefs(state),
      onConfirm,
      showStoreBox,
      submitForm
    }
  }
};
</script>

<style lang="less" scoped>
.staffedit-box{
  width: 100%;
  height: 100vh;
  background: #fff;
}
.mcolor{
  color: #FA3474;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.editInfo {
  line-height: 50px;
  margin-bottom: 13px;
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2;
  font-size: 15px;
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #f2f2f2;
    justify-content: space-between;
    padding-left: 1px;
    padding: 0 15px;
    &:last-child {
      border: none;
    }
    .labelTitle {
      width: 100px;
      flex: none;
    }
    input {
      flex-grow:1;
      /* width: 235px; */
      text-align: right;
      border: none;
      &:disabled{
        background: none;
      }
    }
    .more {
      flex: none;
      width: 7px;
      height: 7px;
      border-top: 1px solid #888;
      border-right: 1px solid #888;
      transform: rotate(45deg);
    }
  }
}

.opt-box {
  height: 89px;
  padding-bottom: env(safe-area-inset-bottom);
  .apply-btn {  
    position: fixed;
    bottom: 15px;
    padding: 0;
    left: 4%;
    width: 92%;
    height: 44px;
    background: #FA3474;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: env(safe-area-inset-bottom);
    border: none;
  }
}
</style>